import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { accessibleRouteChangeHandler } from '@app/utils/utils';
import { PersonalInfoForm } from '@app/PersonalInfo';
import { ProfessionalSummaryForm } from '@app/ProfessionalSummary';
import { EducationForm } from '@app/Education';
import { AcademicHonoursForm } from '@app/AcademicHonours';
import { ResearchPublicationsForm } from '@app/ResearchPublications';
import { SkillsForm } from '@app/Skills';
import { WorkExperienceForm } from '@app/WorkExperience';
import { SpeakerEngagementForm } from '@app/SpeakerEngagements';
import { ProjectsForm } from '@app/Projects';
import { ReferencesForm } from '@app/References';
import { CertificationForm } from '@app/Certification';
import { Preview } from '@app/Preview';
import { GeneralSettings } from '@app/Settings/General/GeneralSettings';
import { ProfileSettings } from '@app/Settings/Profile/ProfileSettings';
import { NotFound } from '@app/NotFound/NotFound';
import { useDocumentTitle } from '@app/utils/useDocumentTitle';
import { LastLocationProvider, useLastLocation } from 'react-router-last-location';
import { Formatting } from './Formatting';

let routeFocusTimer: number;
export interface IAppRoute {
  label?: string; // Excluding the label will exclude the route from the nav sidebar in AppLayout
  /* eslint-disable @typescript-eslint/no-explicit-any */
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  exact?: boolean;
  path: string;
  title: string;
  isAsync?: boolean;
  routes?: undefined;
}

export interface IAppRouteGroup {
  label: string;
  routes: IAppRoute[];
}

export type AppRouteConfig = IAppRoute | IAppRouteGroup;

const routes: AppRouteConfig[] = [
  {
    component: PersonalInfoForm,
    exact: true,
    label: 'My Information',
    path: '/',
    title: 'CareerWheel| Resume Builder',
  },
  {
    component: ProfessionalSummaryForm,
    exact: true,
    isAsync: true,
    label: 'Professional Summary',
    path: '/professional-summary',
    title: 'CareerWheel| Resume Builder',
  },
  {
    component: EducationForm,
    exact: true,
    isAsync: true,
    label: 'Education',
    path: '/education',
    title: 'CareerWheel| Resume Builder',
  },

  {
    component: AcademicHonoursForm,
    exact: true,
    isAsync: true,
    label: 'Academic Honours',
    path: '/academic-honours',
    title: 'CareerWheel| Resume Builder',
  },
  {
    component: ResearchPublicationsForm,
    exact: true,
    isAsync: true,
    label: 'Research Publications/Articles',
    path: '/research-publications',
    title: 'CareerWheel| Resume Builder',
  },
  {
    component: SkillsForm,
    exact: true,
    isAsync: true,
    label: 'Skills',
    path: '/skills',
    title: 'CareerWheel| Resume Builder',
  },
  {
    component: WorkExperienceForm,
    exact: true,
    isAsync: true,
    label: 'Work Experience',
    path: '/work-experience',
    title: 'CareerWheel| Resume Builder',
  },
  {
    component: ProjectsForm,
    exact: true,
    isAsync: true,
    label: 'Projects',
    path: '/projects',
    title: 'CareerWheel| Resume Builder',
  },
  {
    component: SpeakerEngagementForm,
    exact: true,
    isAsync: true,
    label: 'Speaker Engagements / Conferences',
    path: '/speaker-engagements',
    title: 'CareerWheel| Resume Builder',
  },
  {
    component: CertificationForm,
    exact: true,
    isAsync: true,
    label: 'Certifications / Licenses',
    path: '/certification',
    title: 'CareerWheel| Resume Builder',
  },
  {
    component: ReferencesForm,
    exact: true,
    isAsync: true,
    label: 'References',
    path: '/references',
    title: 'CareerWheel| Resume Builder',
  },
  {
    component: Formatting,
    exact: true,
    isAsync: true,
    label: 'Formatting',
    path: '/formatting',
    title: 'CareerWheel| Resume Builder',
  },
  {
    label: 'Settings',
    routes: [
      {
        component: GeneralSettings,
        exact: true,
        label: 'General',
        path: '/settings/general',
        title: 'PatternFly Seed | General Settings',
      },
      {
        component: ProfileSettings,
        exact: true,
        label: 'Profile',
        path: '/settings/profile',
        title: 'PatternFly Seed | Profile Settings',
      },
    ],
  },
];

// a custom hook for sending focus to the primary content container
// after a view has loaded so that subsequent press of tab key
// sends focus directly to relevant content
const useA11yRouteChange = (isAsync: boolean) => {
  const lastNavigation = useLastLocation();
  React.useEffect(() => {
    if (!isAsync && lastNavigation !== null) {
      routeFocusTimer = accessibleRouteChangeHandler();
    }
    return () => {
      window.clearTimeout(routeFocusTimer);
    };
  }, [isAsync, lastNavigation]);
};

const RouteWithTitleUpdates = ({ component: Component, isAsync = false, title, ...rest }: IAppRoute) => {
  useA11yRouteChange(isAsync);
  useDocumentTitle(title);

  function routeWithTitle(routeProps: RouteComponentProps) {
    return <Component {...rest} {...routeProps} />;
  }

  return <Route render={routeWithTitle} />;
};

const PageNotFound = ({ title }: { title: string }) => {
  useDocumentTitle(title);
  return <Route component={NotFound} />;
};

const flattenedRoutes: IAppRoute[] = routes.reduce(
  (flattened, route) => [...flattened, ...(route.routes ? route.routes : [route])],
  [] as IAppRoute[]
);

const AppRoutes = (): React.ReactElement => (
  <LastLocationProvider>
    <Switch>
      {flattenedRoutes.map(({ path, exact, component, title, isAsync }, idx) => (
        <RouteWithTitleUpdates
          path={path}
          exact={exact}
          component={component}
          key={idx}
          title={title}
          isAsync={isAsync}
        />
      ))}
      <PageNotFound title="404 Page Not Found" />
    </Switch>
  </LastLocationProvider>
);

export { AppRoutes, routes };
