import {
  ADD_ACADEMIC_HONOUR,
  ADD_CERTIFICATION,
  ADD_EDUCATION,
  ADD_PROJECT,
  ADD_REFERENCE,
  ADD_RESEARCH_PUBLICATION,
  ADD_SPEAKER_ENGAGEMENT,
  ARRAY_CHILD_CHANGE,
  CHANGE_ITEM,
  REMOVE_ITEM_FROM_ARRAY,
} from '@app/config/constants';
import {
  TAcademicHonours,
  TCertification,
  TEducation,
  TProject,
  TReference,
  TResearchPublication,
  TSpeakerEngagement,
  TWorkExperience,
} from './config/types';
import { IStoreState, TFormDataArrayChildKeys, TFormDataArrayKeys } from './store';

const workExperience = (): TWorkExperience => ({
  job_title: '',
  company_name: '',
  location: '',
  start_date: '',
  end_date: '',
  bullets: [''],
});

const research_publication = (): TResearchPublication => ({
  title: '',
  link: '',
  date: '',
});

const certification = (): TCertification => ({
  name: '',
  date: '',
});
const education = (): TEducation => ({
  programme: '',
  university: '',
  location: '',
  start_date: '',
  end_date: '',
});

const reference = (): TReference => ({
  name: '',
  location: '',
  organisation: '',
  contact: '',
  position: '',
});

const project = (): TProject => ({
  name: '',
  role: '',
  link: '',
  description: '',
  start_date: '',
  end_date: '',
});

const academic_honour = (): TAcademicHonours => ({
  award: '',
  description: '',
  start_date: '',
  end_date: '',
});

const speaker_engagement = (): TSpeakerEngagement => ({
  conference: '',
  role: '',
  location: '',
  date: '',
});

type TArrayChildChange = {
  key: TFormDataArrayChildKeys;
  index: number;
  childKey: string;
  value: any;
};

type TArrayChildItemChange = {
  key: TFormDataArrayChildKeys;
  index: number;
  childIndex: number;
  childKey: string;
  value: any;
};

type TRemoveItemFromArray = {
  key: TFormDataArrayKeys;
  index: number;
};

type TChangeItem = {
  key: string;
  value: any;
};

type TReducerAction =
  | { type: 'ADD_ACADEMIC_HONOUR' }
  | { type: 'ARRAY_CHILD_CHANGE'; payload: TArrayChildChange }
  | { type: 'ARRAY_ITEM_CHILD_CHANGE'; payload: TArrayChildItemChange }
  | { type: 'CHANGE_ITEM'; payload: TChangeItem }
  | { type: 'REMOVE_ITEM_FROM_ARRAY'; payload: TRemoveItemFromArray }
  | { type: 'ADD_CERTIFICATION' }
  | { type: 'ADD_EDUCATION' }
  | { type: 'ADD_PROJECT' }
  | { type: 'ADD_REFERENCE' }
  | { type: 'ADD_RESEARCH_PUBLICATION' }
  | { type: 'ADD_SPEAKER_ENGAGEMENT' };

export const reducer = (state: IStoreState, action: TReducerAction): IStoreState => {
  switch (action.type) {
    case ADD_ACADEMIC_HONOUR:
      return {
        ...state,
        academic_honours: [...state.academic_honours, academic_honour()],
      };

    case ARRAY_CHILD_CHANGE: {
      const { key, childKey, value, index } = action.payload;
      const copyArr = [...state[key]];
      copyArr[index] = {
        ...copyArr[index],
        [childKey]: value,
      };
      return {
        ...state,
        [key]: copyArr,
      };
    }

    case REMOVE_ITEM_FROM_ARRAY: {
      const { key, index } = action.payload;
      const copyArr = [...state[key]].filter((_, i) => i !== index);
      return {
        ...state,
        [key]: copyArr,
      };
    }

    case ADD_CERTIFICATION:
      return {
        ...state,
        certifications: [...state.certifications, certification()],
      };

    case ADD_EDUCATION:
      return {
        ...state,
        education: [...state.education, education()],
      };

    case ADD_PROJECT:
      return {
        ...state,
        projects: [...state.projects, project()],
      };

    case ADD_REFERENCE:
      return {
        ...state,
        references: [...state.references, reference()],
      };

    case ADD_RESEARCH_PUBLICATION:
      return {
        ...state,
        research_publications: [...state.research_publications, research_publication()],
      };

    case ADD_SPEAKER_ENGAGEMENT:
      return {
        ...state,
        speaker_engagements: [...state.speaker_engagements, speaker_engagement()],
      };

    case CHANGE_ITEM: {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    }

    default:
      return state;
  }
};
