import * as React from 'react';
import {
  PageSection,
  Title,
  Form,
  FormGroup,
  TextInput,
  Grid,
  DatePicker,
  FormSection,
  Button,
} from '@patternfly/react-core';
import { StoreContext } from '@app/store';
import { TEducation } from '@app/config/types';
import { ADD_EDUCATION, ARRAY_CHILD_CHANGE, REMOVE_ITEM_FROM_ARRAY } from '@app/config/constants';

const EducationForm: React.FunctionComponent = () => {
  const { state, dispatch } = React.useContext(StoreContext);
  return (
    <PageSection>
      <Title headingLevel="h1" size="lg">
        My Information
      </Title>
      <Form onSubmit={(e) => e.preventDefault()}>
        {state.education.map((item: TEducation, index) => (
          <FormSection key={index}>
            <Grid hasGutter md={6}>
              <FormGroup label="Programme of Study" isRequired fieldId="simple-form-name-01">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-name-01"
                  name="simple-form-name-01"
                  aria-describedby="simple-form-name-01-helper"
                  value={item.programme}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'education',
                        index,
                        childKey: 'programme',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Name of Institution" isRequired fieldId="simple-form-name-02">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-name-02"
                  name="simple-form-name-02"
                  aria-describedby="simple-form-name-02-helper"
                  value={item.university}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'education',
                        index,
                        childKey: 'university',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="GPA / CWA if impressive" fieldId="simple-form-number-01">
                <TextInput
                  type="number"
                  id="simple-form-number-01"
                  name="simple-form-number-01"
                  value={item.grade_point}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'education',
                        index,
                        childKey: 'grade_point',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Academic honour/Awards" isRequired fieldId="simple-form-text-01">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-text-01"
                  name="simple-form-number-01"
                  value={item.awards}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'education',
                        index,
                        childKey: 'awards',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Relevant courses" isRequired fieldId="simple-form-link-01">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-link-01"
                  name="simple-form-link-01"
                  value={item.courses}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'education',
                        index,
                        childKey: 'courses',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Thesis/Project Work" isRequired fieldId="simple-form-link-01">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-link-01"
                  name="simple-form-link-01"
                  value={item.thesis}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'education',
                        index,
                        childKey: 'thesis',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Start Date" isRequired fieldId="simple-form-link-01">
                <DatePicker
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'education',
                        index,
                        childKey: 'start_date',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="End Date" isRequired fieldId="simple-form-link-01">
                <DatePicker
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'education',
                        index,
                        childKey: 'end_date',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <Button
                variant="danger"
                onClick={() =>
                  dispatch({
                    type: REMOVE_ITEM_FROM_ARRAY,
                    payload: {
                      key: 'education',
                      index,
                    },
                  })
                }
              >
                Remove
              </Button>
            </Grid>
          </FormSection>
        ))}
        <Button
          variant="primary"
          onClick={() =>
            dispatch({
              type: ADD_EDUCATION,
            })
          }
        >
          Add Programme of Study
        </Button>
      </Form>
    </PageSection>
  );
};

export { EducationForm };
