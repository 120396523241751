import * as React from 'react';
import {
  PageSection,
  Title,
  Form,
  FormGroup,
  TextInput,
  Grid,
  DatePicker,
  FormSection,
  GridItem,
} from '@patternfly/react-core';
import { TSpeakerEngagement, TWorkExperience } from '@app/config/types';
import { StoreContext } from '@app/store';
import { ARRAY_CHILD_CHANGE, ARRAY_ITEM_CHILD_CHANGE } from '@app/config/constants';

const WorkExperienceForm: React.FunctionComponent = () => {
  const { state, dispatch } = React.useContext(StoreContext);
  return (
    <PageSection>
      <Title headingLevel="h1" size="lg">
        My Information
      </Title>
      <Form onSubmit={(e) => e.preventDefault()}>
        {state.work_experiences.map((item: TWorkExperience, index) => (
          <FormSection key={index}>
            <Grid hasGutter md={6}>
              <FormGroup label="Job Title" isRequired fieldId="simple-form-name-01">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-name-01"
                  name="simple-form-name-01"
                  aria-describedby="simple-form-name-01-helper"
                  value={item.job_title}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'work_experiences',
                        index,
                        childKey: 'job_title',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Company Name" isRequired fieldId="simple-form-name-02">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-name-02"
                  name="simple-form-name-02"
                  aria-describedby="simple-form-name-02-helper"
                  value={item.company_name}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'work_experiences',
                        index,
                        childKey: 'company_name',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Location" isRequired fieldId="simple-form-name-03">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-name-03"
                  name="simple-form-name-03"
                  aria-describedby="simple-form-name-03-helper"
                  value={item.location}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'work_experiences',
                        index,
                        childKey: 'location',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Start Date" isRequired fieldId="simple-form-link-01">
                <DatePicker onChange={(str, date) => console.log('onChange', str, date)} />
              </FormGroup>
              <FormGroup label="End Date" isRequired fieldId="simple-form-link-01">
                <DatePicker onChange={(str, date) => console.log('onChange', str, date)} />
              </FormGroup>
              {item.bullets.map((bullet, idx) => (
                <GridItem span={8} key={idx}>
                  <FormGroup
                    label="Quantified achievements, roles, and responsibilities"
                    isRequired
                    fieldId="simple-form-name-03"
                  >
                    <TextInput
                      isRequired
                      type="text"
                      id="simple-form-name-03"
                      name="simple-form-name-03"
                      aria-describedby="simple-form-name-03-helper"
                      value={bullet}
                      onChange={(value) => {
                        dispatch({
                          type: ARRAY_ITEM_CHILD_CHANGE,
                          payload: {
                            key: 'work_experiences',
                            index,
                            childIndex: idx,
                            childKey: 'bullets',
                            value,
                          },
                        });
                      }}
                    />
                  </FormGroup>
                </GridItem>
              ))}
            </Grid>
          </FormSection>
        ))}
      </Form>
    </PageSection>
  );
};

export { WorkExperienceForm };
