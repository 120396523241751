import * as React from 'react';
import { PageSection, Title, Grid, GridItem, FormSelect, FormSelectOption } from '@patternfly/react-core';
import { CirclePicker } from 'react-color';
import { StoreContext } from '@app/store';
import { CHANGE_ITEM } from '@app/config/constants';
import { Preview } from '@app/Preview';

const Formatting: React.FunctionComponent = () => {
  const { state, dispatch } = React.useContext(StoreContext);
  const fontOptions = [
    'Calibri',
    'Georgia',
    'Times New Roman',
    'Helvetica',
    'Cambria',
    'Garamond',
    'Didot',
    'Trebuchet',
    'Verdana',
    'Arial',
    'Monteserrat',
  ];
  const handleChangeComplete = (color) => {
    dispatch({
      type: CHANGE_ITEM,
      payload: {
        key: 'color',
        value: color.hex,
      },
    });
  };

  const handleChange = (key: string, value: string) => {
    console.log(value, key);
    dispatch({
      type: CHANGE_ITEM,
      payload: {
        key,
        value,
      },
    });
  };
  return (
    <PageSection>
      <Title headingLevel="h1" size="lg">
        My Information
      </Title>
      <Grid hasGutter>
        <GridItem span={5}>
          <Grid>
            <GridItem span={8}>
              <CirclePicker color={state.color} onChangeComplete={handleChangeComplete} />
            </GridItem>
            <GridItem>
              <FormSelect
                value={state.font}
                onChange={(value) => handleChange('font', value)}
                aria-label="FormSelect Input"
              >
                {fontOptions.map((option, index) => (
                  <FormSelectOption key={index} value={option} label={option} />
                ))}
              </FormSelect>
            </GridItem>
            <GridItem>
              {/* <Slider value={this.state.value1} onChange={this.onChange} customSteps={this.steps} /> */}
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem span={7}>
          <Preview />
        </GridItem>
      </Grid>
    </PageSection>
  );
};

export { Formatting };
