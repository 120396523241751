import * as React from 'react';
import {
  PageSection,
  Title,
  Form,
  FormGroup,
  TextInput,
  DatePicker,
  Grid,
  FormSection,
  Button,
} from '@patternfly/react-core';
import { TSpeakerEngagement } from '@app/config/types';
import { StoreContext } from '@app/store';
import { ADD_SPEAKER_ENGAGEMENT, ARRAY_CHILD_CHANGE, REMOVE_ITEM_FROM_ARRAY } from '@app/config/constants';

const SpeakerEngagementForm: React.FunctionComponent = () => {
  const { state, dispatch } = React.useContext(StoreContext);
  return (
    <PageSection>
      <Title headingLevel="h1" size="lg">
        My Information
      </Title>
      <Form onSubmit={(e) => e.preventDefault()}>
        {state.speaker_engagements.map((item: TSpeakerEngagement, index) => (
          <FormSection key={index}>
            <Grid hasGutter md={6}>
              <FormGroup label="Role" isRequired fieldId="simple-form-name-01">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-name-01"
                  name="simple-form-name-01"
                  aria-describedby="simple-form-name-01-helper"
                  value={item.role}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'speaker_engagements',
                        index,
                        childKey: 'role',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Conference/Summit" isRequired fieldId="simple-form-name-02">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-name-02"
                  name="simple-form-name-02"
                  aria-describedby="simple-form-name-02-helper"
                  value={item.conference}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'speaker_engagements',
                        index,
                        childKey: 'conference',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Location" isRequired fieldId="simple-form-name-02">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-name-02"
                  name="simple-form-name-02"
                  aria-describedby="simple-form-name-02-helper"
                  value={item.location}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'speaker_engagements',
                        index,
                        childKey: 'location',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Date" isRequired fieldId="simple-form-link-01">
                <DatePicker
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'speaker_engagements',
                        index,
                        childKey: 'date',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <Button
                variant="danger"
                onClick={() =>
                  dispatch({
                    type: REMOVE_ITEM_FROM_ARRAY,
                    payload: {
                      key: 'speaker_engagements',
                      index,
                    },
                  })
                }
              >
                Remove
              </Button>
            </Grid>
          </FormSection>
        ))}
        <Button
          variant="primary"
          onClick={() =>
            dispatch({
              type: ADD_SPEAKER_ENGAGEMENT,
            })
          }
        >
          Add Speaker Engagements / Conferences
        </Button>
      </Form>
    </PageSection>
  );
};

export { SpeakerEngagementForm };
