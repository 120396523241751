import * as React from 'react';
import { PageSection, Title, Form, FormGroup, TextInput, Grid } from '@patternfly/react-core';

const SkillsForm: React.FunctionComponent = () => (
  <PageSection>
    <Title headingLevel="h1" size="lg">
      My Information
    </Title>
    <Form>
      <Grid hasGutter md={6}>
        <FormGroup label="Technical Skills" isRequired fieldId="simple-form-name-01">
          <TextInput
            isRequired
            type="text"
            id="simple-form-name-01"
            name="simple-form-name-01"
            aria-describedby="simple-form-name-01-helper"
            // value={value1}
            // onChange={this.handleTextInputChange1}
          />
        </FormGroup>
        <FormGroup label="Software Skills" isRequired fieldId="simple-form-name-02">
          <TextInput
            isRequired
            type="text"
            id="simple-form-name-02"
            name="simple-form-name-02"
            aria-describedby="simple-form-name-02-helper"
            // value={value1}
            // onChange={this.handleTextInputChange1}
          />
        </FormGroup>
        <FormGroup label="Language" isRequired fieldId="simple-form-name-03">
          <TextInput
            isRequired
            type="text"
            id="simple-form-name-03"
            name="simple-form-name-03"
            aria-describedby="simple-form-name-03-helper"
            // value={value1}
            // onChange={this.handleTextInputChange1}
          />
        </FormGroup>
      </Grid>
    </Form>
  </PageSection>
);

export { SkillsForm };
