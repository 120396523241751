import * as React from 'react';
import {
  PageSection,
  Title,
  Form,
  FormGroup,
  TextInput,
  Split,
  SplitItem,
  Grid,
  GridItem,
} from '@patternfly/react-core';
import { StoreContext } from '@app/store';
import { CHANGE_ITEM } from '@app/config/constants';
import { Preview } from '@app/Preview';

const PersonalInfoForm: React.FunctionComponent = () => {
  const { state, dispatch } = React.useContext(StoreContext);
  return (
    <PageSection>
      <Title headingLevel="h1" size="lg">
        My Information
      </Title>
      <Grid hasGutter>
        <GridItem span={5}>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Grid hasGutter md={12}>
              <FormGroup label="First Name(s)" isRequired fieldId="simple-form-name-01">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-name-01"
                  name="simple-form-name-01"
                  aria-describedby="simple-form-name-01-helper"
                  value={state.first_name}
                  onChange={(value) =>
                    dispatch({
                      type: CHANGE_ITEM,
                      payload: {
                        key: 'first_name',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Last Name" isRequired fieldId="simple-form-name-02">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-name-02"
                  name="simple-form-name-02"
                  aria-describedby="simple-form-name-02-helper"
                  value={state.last_name}
                  onChange={(value) =>
                    dispatch({
                      type: CHANGE_ITEM,
                      payload: {
                        key: 'last_name',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>

              <FormGroup label="Email" isRequired fieldId="simple-form-email-01">
                <TextInput
                  isRequired
                  type="email"
                  id="simple-form-email-01"
                  name="simple-form-email-01"
                  value={state.email}
                  onChange={(value) =>
                    dispatch({
                      type: CHANGE_ITEM,
                      payload: {
                        key: 'email',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Phone number" isRequired fieldId="simple-form-number-01">
                <TextInput
                  isRequired
                  type="tel"
                  id="simple-form-number-01"
                  placeholder="555-555-5555"
                  name="simple-form-number-01"
                  value={state.phone_number}
                  onChange={(value) =>
                    dispatch({
                      type: CHANGE_ITEM,
                      payload: {
                        key: 'phone_number',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Location" isRequired fieldId="simple-form-link-01">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-location-01"
                  name="simple-form-location-01"
                  value={state.address}
                  onChange={(value) =>
                    dispatch({
                      type: CHANGE_ITEM,
                      payload: {
                        key: 'address',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="LinkedIn Link" isRequired fieldId="simple-form-link-01">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-link-01"
                  placeholder="www.linkedin.com/in/[firstname][lastname]"
                  name="simple-form-link-01"
                  value={state.linkedIn}
                  onChange={(value) =>
                    dispatch({
                      type: CHANGE_ITEM,
                      payload: {
                        key: 'linkedIn',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
            </Grid>
          </Form>
        </GridItem>
        <GridItem span={7}>
          <Preview />
        </GridItem>
      </Grid>
    </PageSection>
  );
};

export { PersonalInfoForm };
