import * as React from 'react';
import { PageSection, Title, Form, FormGroup, TextInput, Button, Grid, FormSection } from '@patternfly/react-core';
import { TReference } from '@app/config/types';
import { StoreContext } from '@app/store';
import { ADD_REFERENCE, ARRAY_CHILD_CHANGE, REMOVE_ITEM_FROM_ARRAY } from '@app/config/constants';

const ReferencesForm: React.FunctionComponent = () => {
  const { state, dispatch } = React.useContext(StoreContext);
  return (
    <PageSection>
      <Title headingLevel="h1" size="lg">
        My Information
      </Title>
      <Form onSubmit={(e) => e.preventDefault()}>
        {state.references.map((item: TReference, index) => (
          <FormSection key={index}>
            <Grid hasGutter md={6}>
              <FormGroup label="Full Name" isRequired fieldId="simple-form-name-01">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-name-01"
                  name="simple-form-name-01"
                  aria-describedby="simple-form-name-01-helper"
                  value={item.name}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'references',
                        index,
                        childKey: 'name',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Position/Job Title" isRequired fieldId="simple-form-name-02">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-name-02"
                  name="simple-form-name-02"
                  aria-describedby="simple-form-name-02-helper"
                  value={item.position}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'references',
                        index,
                        childKey: 'position',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>

              <FormGroup label="Organisation" isRequired fieldId="simple-form-email-01">
                <TextInput
                  isRequired
                  type="email"
                  id="simple-form-email-01"
                  name="simple-form-email-01"
                  value={item.organisation}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'references',
                        index,
                        childKey: 'organisation',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Location" isRequired fieldId="simple-form-number-01">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-number-01"
                  name="simple-form-number-01"
                  value={item.location}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'references',
                        index,
                        childKey: 'location',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Contact Details(Email/Phone Number)" isRequired fieldId="simple-form-link-01">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-link-01"
                  name="simple-form-link-01"
                  value={item.contact}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'references',
                        index,
                        childKey: 'contact',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <Button
                variant="danger"
                onClick={() =>
                  dispatch({
                    type: REMOVE_ITEM_FROM_ARRAY,
                    payload: {
                      key: 'references',
                      index,
                    },
                  })
                }
              >
                Remove
              </Button>
            </Grid>
          </FormSection>
        ))}
        <Button
          variant="primary"
          onClick={() =>
            dispatch({
              type: ADD_REFERENCE,
            })
          }
        >
          Add Reference
        </Button>
      </Form>
    </PageSection>
  );
};

export { ReferencesForm };
