export function accessibleRouteChangeHandler() {
  return window.setTimeout(() => {
    const mainContainer = document.getElementById('primary-app-container');
    if (mainContainer) {
      mainContainer.focus();
    }
  }, 50);
}

export function convertListToString(list: string[]): string {
  if (list.length < 3) {
    return punctuate(list.join(' and '));
  } else {
    return `${list.slice(0, list.length - 1).join(', ')} and ${list[list.length - 1]}.`;
  }
}

export function renderIndefiniteArticle(word: string): string {
  if (['a', 'e', 'i', 'o', 'u'].includes(word[0].toLowerCase())) {
    return 'An';
  }
  return 'A';
}

export function punctuate(text: string): string {
  return text ? `${text}.` : text;
}
