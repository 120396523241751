import * as React from 'react';
import { PageSection, Title, Form, FormGroup, TextInput, Button, Grid, GridItem } from '@patternfly/react-core';

const ProfessionalSummaryForm: React.FunctionComponent = () => (
  <PageSection>
    <Title headingLevel="h1" size="lg">
      My Information
    </Title>
    <Form>
      <Grid hasGutter md={6}>
        <FormGroup label="Target Job title" isRequired fieldId="simple-form-name-01">
          <TextInput
            isRequired
            type="text"
            id="simple-form-name-01"
            name="simple-form-name-01"
            aria-describedby="simple-form-name-01-helper"
            // value={value1}
            // onChange={this.handleTextInputChange1}
          />
        </FormGroup>
        <FormGroup label="Current Job title" isRequired fieldId="simple-form-name-02">
          <TextInput
            isRequired
            type="text"
            id="simple-form-name-02"
            name="simple-form-name-02"
            aria-describedby="simple-form-name-02-helper"
            // value={value1}
            // onChange={this.handleTextInputChange1}
          />
        </FormGroup>
        <FormGroup label="Years of experience" isRequired fieldId="simple-form-number-01">
          <TextInput
            isRequired
            type="number"
            id="simple-form-number-01"
            name="simple-form-number-01"
            // value={value2}
            // onChange={this.handleTextInputChange2}
          />
        </FormGroup>
        <FormGroup label="Core Responsibility" isRequired fieldId="simple-form-text-01">
          <TextInput
            isRequired
            type="text"
            id="simple-form-text-01"
            name="simple-form-number-01"
            // value={value3}
            // onChange={this.handleTextInputChange3}
          />
        </FormGroup>
        <FormGroup label="Top achievement in line with job title above" isRequired fieldId="simple-form-link-01">
          <TextInput
            isRequired
            type="text"
            id="simple-form-link-01"
            name="simple-form-link-01"
            // value={value3}
            // onChange={this.handleTextInputChange3}
          />
        </FormGroup>
        <FormGroup label="Key technical skills" isRequired fieldId="simple-form-link-01">
          <TextInput
            isRequired
            type="text"
            id="simple-form-link-01"
            name="simple-form-link-01"
            // value={value3}
            // onChange={this.handleTextInputChange3}
          />
        </FormGroup>
        <FormGroup label="Research Experience" isRequired fieldId="simple-form-link-01">
          <TextInput
            isRequired
            type="text"
            id="simple-form-link-01"
            name="simple-form-link-01"
            // value={value3}
            // onChange={this.handleTextInputChange3}
          />
        </FormGroup>
        <FormGroup
          label=" What key impact do you want to deliver to organisations you work with?"
          isRequired
          fieldId="simple-form-link-01"
        >
          <TextInput
            isRequired
            type="text"
            id="simple-form-link-01"
            name="simple-form-link-01"
            // value={value3}
            // onChange={this.handleTextInputChange3}
          />
        </FormGroup>
      </Grid>
    </Form>
  </PageSection>
);

export { ProfessionalSummaryForm };
