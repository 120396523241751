import * as React from 'react';
import { get } from 'lodash';
import { convertListToString, punctuate, renderIndefiniteArticle } from '@app/utils/utils';
import { format } from 'date-fns';
import { StoreContext } from '@app/store';
import { PageSection, Title, Card, CardTitle, CardBody, CardFooter } from '@patternfly/react-core';

export const Preview: React.FunctionComponent = () => {
  const { state: data } = React.useContext(StoreContext);
  const keyTechnicalSkills = convertListToString(get(data, 'skills') || []);
  const technicalSkills = punctuate((get(data, 'technical_skills') || []).join(', '));
  const softwareSkills = punctuate((get(data, 'software_skills') || []).join(', '));
  const languages = punctuate((get(data, 'languages') || []).join(', '));
  return (
    <Card>
      <CardBody>
        <table width="100%" style={{ borderSpacing: 0, fontFamily: data.font }}>
          <tbody>
            <tr>
              <td style={{ padding: 5, fontWeight: 'bold', color: data.color, textAlign: 'center' }} colSpan={2}>
                {get(data, 'first_name', 'FIRST NAME(S)').toUpperCase() || 'FIRST NAME(S)'}{' '}
                {get(data, 'last_name', 'NAME').toUpperCase() || 'LAST NAME'}
              </td>
            </tr>
            <tr>
              <td style={{ padding: 5, color: '#000', textAlign: 'center' }} colSpan={2}>
                {get(data, 'address') || 'Location'} | {get(data, 'phone_number') || 'Phone Number'} |{' '}
                {get(data, 'email') || 'Email address'} |{' '}
                {get(data, 'linkedIn') || 'www.linkedin.com/in/[firstname][lastname]'}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'left',
                  fontWeight: 'bold',
                  color: data.color,
                  paddingTop: 16,
                }}
                colSpan={2}
              >
                {get(data, 'target_job_title').toUpperCase() || '[TARGET JOB]'} PROFESSIONAL SUMMARY
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p
                  style={{
                    textAlign: 'left',
                    color: '#000',
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  {renderIndefiniteArticle(get(data, 'current_job_title') || '[Your job title]')}{' '}
                  {get(data, 'current_job_title') || '[Your job title]'} with{' '}
                  {get(data, 'years_of_experience') || '[X]'} year
                  {`${get(data, 'years_of_experience') > 1 ? 's' : ''}`} of experience{' '}
                  {get(data, 'industry') || '[insert core responsibility]'}
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    paddingLeft: 18,
                    textAlign: 'left',
                    color: '#000',
                  }}
                >
                  {(get(data, 'top_achievement') || ['']).map((item, index) => (
                    <li key={index}>{item ? item : 'Top achievement in line with job title above'}</li>
                  ))}
                  <li>Proficient in {keyTechnicalSkills ? keyTechnicalSkills : '[List key technical skills]'}</li>
                  <li>Research interests include {get(data, 'research_interest') || '[list research interests]'}</li>
                  <li>{get(data, 'value') || '[Insert value you are bringing to the table]'}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'left',
                  borderBottom: '1px solid black',
                  color: data.color,
                  fontWeight: 'bold',
                }}
                colSpan={2}
              >
                SKILLS
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                {/* <p style={{textAlign: "left", color: "#000"}}> 
                  Technical/Transferable skill 1:  Insert quantified achievement that reflects this skill.
                </p>
                 <p style={{textAlign: "left", color: "#000"}}> 
                  Technical/Transferable skill 2:  Insert quantified achievement that reflects this skill.
                </p>
                 <p style={{textAlign: "left", color: "#000"}}> 
                  Technical/Transferable skill 3:  Insert quantified achievement that reflects this skill.
                </p>
                 <p style={{textAlign: "left", color: "#000"}}> 
                  Technical/Transferable skill 4:  Insert quantified achievement that reflects this skill.
                </p> */}
                <p style={{ textAlign: 'left', color: '#000', marginTop: 0 }}>
                  <b>Technical skills</b>: {technicalSkills} <br />
                  <b>Software skills</b>: {softwareSkills}
                  <br />
                  <b>Languages</b>: {languages}
                  <br />
                </p>
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{
                  textAlign: 'left',
                  borderBottom: '1px solid black',
                  color: data.color,
                  fontWeight: 'bold',
                }}
              >
                WORK EXPERIENCE
              </td>
            </tr>
            {data.work_experiences.map((item, index) => (
              <React.Fragment key={index}>
                <tr style={{ verticalAlign: 'top' }}>
                  <td width="70%" style={{ textAlign: 'left', color: '#000', fontWeight: 'bold' }}>
                    {`${item.job_title ? item.job_title : 'Job Title'}, ${
                      item.company_name ? item.company_name : 'Company Name'
                    }, ${item.location ? item.location : 'Location'}`}
                    .
                  </td>
                  <td width="30%" style={{ textAlign: 'right', color: '#000' }}>
                    {item.start_date ? format(new Date(item.start_date), 'MMM. yyyy') : 'Mmm. YYYY'} –{' '}
                    {item.end_date ? format(new Date(item.end_date), 'MMM. yyyy') : 'Mmm. YYYY'}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left', color: '#000' }} colSpan={2}>
                    <ul style={{ marginTop: 0, paddingLeft: 18, marginBottom: 0 }}>
                      {item.bullets.map((bullet, idx) => (
                        <li key={idx}>{`${
                          bullet ? bullet : 'Insert quantified achievements, roles, and responsibilities'
                        }`}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </React.Fragment>
            ))}

            <tr>
              <td
                colSpan={2}
                style={{
                  textAlign: 'left',
                  borderBottom: '1px solid black',
                  color: data.color,
                  fontWeight: 'bold',
                  paddingTop: 16,
                }}
              >
                NOT-FOR-PROFIT WORK
              </td>
            </tr>
            {[1].map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td width="70%" style={{ textAlign: 'left', color: '#000', fontWeight: 'bold' }}>
                    Position, Name of Organization/Community, Location.
                  </td>
                  <td width="30%" style={{ textAlign: 'right', color: '#000' }}>
                    Mmm. YYYY – present
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left', color: data.color }} colSpan={2}>
                    <ul style={{ marginTop: 0, paddingLeft: 18, textAlign: 'left', color: '#000' }}>
                      <li>Insert quantified achievements, roles, and responsibilities</li>
                    </ul>
                  </td>
                </tr>
              </React.Fragment>
            ))}
            <tr>
              <td
                colSpan={2}
                style={{
                  textAlign: 'left',
                  borderBottom: '1px solid black',
                  color: data.color,
                  fontWeight: 'bold',
                  paddingTop: 16,
                }}
              >
                EDUCATION
              </td>
            </tr>
            {data.education.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td width="70%" style={{ textAlign: 'left', color: '#000', fontWeight: 'bold' }}>
                    {`${item.programme ? item.programme : 'Programme of Study'} | ${
                      item.grade_point ? item.grade_point : 'GPA/CWA if impressive'
                    }`}
                  </td>
                  <td width="30%" style={{ textAlign: 'right', color: '#000' }}>
                    {item.start_date ? format(new Date(item.start_date), 'MMM. yyyy') : 'Mmm. YYYY'} –{' '}
                    {item.end_date ? format(new Date(item.end_date), 'MMM. yyyy') : 'Mmm. YYYY'}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left', color: '#000' }} colSpan={2}>
                    {`${item.university ? item.university : 'Name of Institution'}`},{' '}
                    {`${item.location ? item.location : 'Location'}`}.
                  </td>
                </tr>
                <tr style={{ textAlign: 'left', color: '#000' }}>
                  <td>{`${item.awards ? item.awards : '[Insert academic honour/awards if any]'}`}</td>
                </tr>
                <tr style={{ textAlign: 'left', color: '#000' }}>
                  <td>{`${item.courses ? item.courses : 'Relevant courses:'}`} </td>
                </tr>
                <tr style={{ textAlign: 'left', color: '#000' }}>
                  <td>{`${item.thesis ? item.thesis : 'Thesis/Project work: Title | Link'}`} </td>
                </tr>
              </React.Fragment>
            ))}
            <tr>
              <td
                colSpan={2}
                style={{
                  textAlign: 'left',
                  borderBottom: '1px solid black',
                  color: data.color,
                  fontWeight: 'bold',
                  paddingTop: 16,
                }}
              >
                ACADEMIC HONOURS/AWARDS/GRANTS
              </td>
            </tr>
            {data.academic_honours.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td width="70%" style={{ textAlign: 'left', color: '#000', fontWeight: 'bold' }}>
                    {`${item.award ? item.award : '[Name of Award]'} `}
                  </td>
                  <td width="30%" style={{ textAlign: 'right', color: '#000' }}>
                    {item.start_date ? format(new Date(item.start_date), 'MMM. yyyy') : 'Mmm. YYYY'} –{' '}
                    {item.end_date ? format(new Date(item.end_date), 'MMM. yyyy') : 'Mmm. YYYY'}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left', color: '#000' }} colSpan={2}>
                    {`${item.description ? item.description : '[Description of Award]'}`}
                  </td>
                </tr>
              </React.Fragment>
            ))}
            <tr>
              <td
                colSpan={2}
                style={{
                  textAlign: 'left',
                  borderBottom: '1px solid black',
                  color: data.color,
                  fontWeight: 'bold',
                  paddingTop: 16,
                }}
              >
                PROJECTS
              </td>
            </tr>
            {data.projects.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td width="70%" style={{ textAlign: 'left', color: '#000', fontWeight: 'bold' }}>
                    {`${item.role ? item.role : 'Role'}, ${item.name ? item.name : 'Project Name'}`}
                    <span
                      style={{
                        color: '#000',
                        fontWeight: 'normal',
                      }}
                    >{` | ${item.link ? item.link : 'Link to project'}`}</span>
                  </td>
                  <td width="30%" style={{ textAlign: 'right', color: '#000' }}>
                    {item.start_date ? format(new Date(item.start_date), 'MMM. yyyy') : 'Mmm. YYYY'} –{' '}
                    {item.end_date ? format(new Date(item.end_date), 'MMM. yyyy') : 'Mmm. YYYY'}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left', color: '#000' }} colSpan={2}>
                    {`${item.description ? item.description : 'Project Description'}`}
                  </td>
                </tr>
              </React.Fragment>
            ))}

            <tr>
              <td
                colSpan={2}
                style={{
                  textAlign: 'left',
                  borderBottom: '1px solid black',
                  color: data.color,
                  fontWeight: 'bold',
                  paddingTop: 16,
                }}
              >
                REFERENCES
              </td>
            </tr>
            {data.references.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td style={{ textAlign: 'left', color: '#000' }}>{`${item.name ? item.name : '[Full Name]'}`}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left', color: '#000' }} colSpan={2}>
                    {`${item.position ? item.position : '[Position/Job Title]'}`}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left', color: '#000' }} colSpan={2}>
                    {`${item.organisation ? item.organisation : '[Organisation]'}`}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left', color: '#000' }} colSpan={2}>
                    {`${item.location ? item.location : '[Location]'}`}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'left', color: '#000' }} colSpan={2}>
                    {`${item.contact ? item.contact : '[Contact details (Email/Phone number)]'}`}
                  </td>
                </tr>
              </React.Fragment>
            ))}

            <tr>
              <td
                colSpan={2}
                style={{
                  textAlign: 'left',
                  borderBottom: '1px solid black',
                  color: data.color,
                  fontWeight: 'bold',
                  paddingTop: 16,
                }}
              >
                RESEARCH PUBLICATIONS/ARTICLES
              </td>
            </tr>
            {data.research_publications.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td style={{ textAlign: 'left', color: '#000' }} width="70%">
                    {`${item.title ? item.title : 'Title of Publication'} ${
                      item.link ? '| ' + item.link : '| Link to publication'
                    }`}
                  </td>
                  <td width="30%">
                    {' '}
                    <td width="30%" style={{ textAlign: 'right', color: '#000' }}>
                      {item.date ? format(new Date(item.date), 'MMM. yyyy') : 'Mmm. YYYY'}
                    </td>
                  </td>
                </tr>
              </React.Fragment>
            ))}
            <tr>
              <td
                colSpan={2}
                style={{
                  textAlign: 'left',
                  borderBottom: '1px solid black',
                  color: data.color,
                  fontWeight: 'bold',
                  paddingTop: 16,
                }}
              >
                SPEAKER ENGAGEMENTS/CONFERENCES
              </td>
            </tr>
            {data.speaker_engagements.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td style={{ textAlign: 'left', color: '#000' }} width="70%">
                    {`${item.role ? item.role : 'Role'}, ${item.conference ? item.conference : 'Conference/Summit'}, ${
                      item.location ? item.location : 'Location'
                    }`}
                  </td>
                  <td width="30%">
                    {' '}
                    <td width="30%" style={{ textAlign: 'right', color: '#000' }}>
                      {item.date ? format(new Date(item.date), 'MMM. yyyy') : 'Mmm. YYYY'}
                    </td>
                  </td>
                </tr>
              </React.Fragment>
            ))}

            <tr>
              <td
                colSpan={2}
                style={{
                  textAlign: 'left',
                  borderBottom: '1px solid black',
                  color: data.color,
                  fontWeight: 'bold',
                  paddingTop: 16,
                }}
              >
                LICENCES/CERTIFICATIONS
              </td>
            </tr>
            {data.certifications.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td style={{ textAlign: 'left', color: '#000' }} width="70%">
                    {`${item.name ? item.name : 'Certification/Licence'}`}
                  </td>
                  <td width="30%">
                    {' '}
                    <td width="30%" style={{ textAlign: 'right', color: '#000' }}>
                      {item.date ? format(new Date(item.date), 'MMM. yyyy') : 'Mmm. YYYY'}
                    </td>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};
