import React, { useReducer, createContext } from 'react';
import { reducer } from '@app/reducers';
import {
  TWorkExperience,
  TResearchPublication,
  TCertification,
  TEducation,
  TReference,
  TProject,
  TAcademicHonours,
  TSpeakerEngagement,
} from '@app/config/types';

const workExperience = (): TWorkExperience => ({
  job_title: '',
  company_name: '',
  location: '',
  start_date: '',
  end_date: '',
  bullets: [''],
});

const research_publication = (): TResearchPublication => ({
  title: '',
  link: '',
  date: '',
});

const certification = (): TCertification => ({
  name: '',
  date: '',
});

const education = (): TEducation => ({
  programme: '',
  university: '',
  location: '',
  start_date: '',
  end_date: '',
});

const reference = (): TReference => ({
  name: '',
  location: '',
  organisation: '',
  contact: '',
  position: '',
});

const project = (): TProject => ({
  name: '',
  role: '',
  link: '',
  description: '',
  start_date: '',
  end_date: '',
});

const academic_honour = (): TAcademicHonours => ({
  award: '',
  description: '',
  start_date: '',
  end_date: '',
});

const speaker_engagement = (): TSpeakerEngagement => ({
  conference: '',
  role: '',
  location: '',
  date: '',
});

export type TFormDataArrayKeys =
  | 'skills'
  | 'top_achievement'
  | 'technical_skills'
  | 'software_skills'
  | 'languages'
  | 'work_experiences'
  | 'education'
  | 'academic_honours'
  | 'projects'
  | 'references'
  | 'speaker_engagements'
  | 'research_publications'
  | 'certifications';
export type TFormDataArrayChildKeys =
  | 'work_experiences'
  | 'education'
  | 'academic_honours'
  | 'projects'
  | 'references'
  | 'speaker_engagements'
  | 'research_publications'
  | 'certifications';
export type TNestedChildKeys = 'bullets';

export interface IStoreState {
  color: string;
  font: string;
  first_name: string;
  last_name: string;
  email: string;
  address: string;
  phone_number: string;
  linkedIn: string;
  current_job_title: string;
  target_job_title: string;
  years_of_experience: number;
  industry: string;
  top_achievement: string[];
  value: string;
  research_interest?: string;
  skills: string[];
  software_skills: string[];
  technical_skills: string[];
  languages: string[];
  work_experiences: TWorkExperience[];
  academic_honours: TAcademicHonours[];
  education: TEducation[];
  projects: TProject[];
  references: TReference[];
  research_publications: TResearchPublication[];
  speaker_engagements: TSpeakerEngagement[];
  certifications: TCertification[];
}

interface IStoreContext {
  state: IStoreState;
  dispatch: (args?: any) => void;
}

const initialState: IStoreState = {
  color: '#000',
  font: 'Calibri',
  first_name: '',
  last_name: '',
  address: '',
  phone_number: '',
  email: '',
  linkedIn: '',
  years_of_experience: 0,
  current_job_title: '',
  target_job_title: '',
  industry: '',
  top_achievement: [''],
  value: '',
  skills: [''],
  technical_skills: [''],
  software_skills: [''],
  languages: [''],
  academic_honours: [academic_honour()],
  work_experiences: [workExperience()],
  education: [education()],
  projects: [project()],
  references: [reference()],
  speaker_engagements: [speaker_engagement()],
  research_publications: [research_publication()],
  certifications: [certification()],
};

const defaultStoreContext: IStoreContext = {
  state: initialState,
  dispatch: () => null,
};

interface IStore {
  children: React.ReactNode;
}

export const Store: React.FunctionComponent<IStore> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>;
};

export const StoreContext = createContext<IStoreContext>(defaultStoreContext);
