import * as React from 'react';
import {
  PageSection,
  Title,
  Form,
  FormGroup,
  TextInput,
  DatePicker,
  Grid,
  FormSection,
  Button,
} from '@patternfly/react-core';
import { StoreContext } from '@app/store';
import { TAcademicHonours } from '@app/config/types';
import { ADD_ACADEMIC_HONOUR, ARRAY_CHILD_CHANGE, REMOVE_ITEM_FROM_ARRAY } from '@app/config/constants';

const AcademicHonoursForm: React.FunctionComponent = () => {
  const { state, dispatch } = React.useContext(StoreContext);
  return (
    <PageSection>
      <Title headingLevel="h1" size="lg">
        My Information
      </Title>
      <Form onSubmit={(e) => e.preventDefault()}>
        {state.academic_honours.map((item: TAcademicHonours, index) => (
          <FormSection key={index}>
            <Grid hasGutter md={6}>
              <FormGroup label="Name of Award" isRequired fieldId="simple-form-name-01">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-name-01"
                  name="simple-form-name-01"
                  aria-describedby="simple-form-name-01-helper"
                  value={item.award}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'academic_honours',
                        index,
                        childKey: 'award',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Description of Award" isRequired fieldId="simple-form-name-02">
                <TextInput
                  isRequired
                  type="text"
                  id="simple-form-name-02"
                  name="simple-form-name-02"
                  aria-describedby="simple-form-name-02-helper"
                  value={item.description}
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'academic_honours',
                        index,
                        childKey: 'description',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Start Date" isRequired fieldId="simple-form-link-01">
                <DatePicker
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'academic_honours',
                        index,
                        childKey: 'start_date',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="End Date" isRequired fieldId="simple-form-link-01">
                <DatePicker
                  onChange={(value) =>
                    dispatch({
                      type: ARRAY_CHILD_CHANGE,
                      payload: {
                        key: 'academic_honours',
                        index,
                        childKey: 'end_date',
                        value,
                      },
                    })
                  }
                />
              </FormGroup>
              <Button
                variant="danger"
                onClick={() =>
                  dispatch({
                    type: REMOVE_ITEM_FROM_ARRAY,
                    payload: {
                      key: 'academic_honours',
                      index,
                    },
                  })
                }
              >
                Remove Academic Honour
              </Button>
            </Grid>
          </FormSection>
        ))}
        <Button
          variant="primary"
          onClick={() =>
            dispatch({
              type: ADD_ACADEMIC_HONOUR,
            })
          }
        >
          Add Academic Honour
        </Button>
      </Form>
    </PageSection>
  );
};

export { AcademicHonoursForm };
