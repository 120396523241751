export const REMOVE_ITEM_FROM_ARRAY = 'REMOVE_ITEM_FROM_ARRAY';
export const ADD_ITEM_TO_ARRAY = 'ADD_ITEM_TO_ARRAY';
export const ARRAY_CHILD_CHANGE = 'ARRAY_CHILD_CHANGE';
export const ADD_RESEARCH_PUBLICATION = 'ADD_RESEARCH_PUBLICATION';
export const ADD_SPEAKER_ENGAGEMENT = 'ADD_SPEAKER_ENGAGEMENT';
export const ADD_REFERENCE = 'ADD_REFERENCE';
export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_ACADEMIC_HONOUR = 'ADD_ACADEMIC_HONOUR';
export const ADD_CERTIFICATION = 'ADD_CERTIFICATION';
export const ADD_EDUCATION = 'ADD_EDUCATION';
export const ADD_WORK_EXPERIENCE = 'ADD_WORK_EXPERIENCE';
export const ADD_ITEM_TO_ARRAY_CHILD = 'ADD_ITEM_TO_ARRAY_CHILD';
export const CHANGE_ITEM = 'CHANGE_ITEM';
export const ARRAY_CHANGE = 'ARRAY_CHANGE';
export const ARRAY_ITEM_CHILD_CHANGE = 'ARRAY_ITEM_CHILD_CHANGE';
export const REMOVE_ITEM_FROM_ARRAY_CHILD = 'REMOVE_ITEM_FROM_ARRAY_CHILD';
